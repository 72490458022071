import React from 'react';
import './blog.css';
import { Helmet } from 'react-helmet';

const five_best_thumbnail = "https://hostbuddylb.com/blog/5_best_PMS_6-13/Thumbnail_small.webp";
const automate_str_thumbnail = "https://hostbuddylb.com/blog/automate_str_6-25/thumbnail.webp";

const BlogLandingPage = () => {

    const articles = [
      { id:"automate_str_6-25", title:"How to Automate Your Short Term Rental Business", date:"Jun 25, 2024", img:automate_str_thumbnail, description:"STR automation mastery: Uncover tech-driven solutions, AI-powered tools, and clever hacks to minimize effort and maximize returns for your short-term rentals." },
      { id:"5_best_PMS_6-13", title:"5 Best Property Management Software of 2024 (Updated)", date:"Jun 13, 2024", img:five_best_thumbnail, description:"Elevate your STR game with 2024's best management software. Find out which tools offer the latest tech and clever automation for hosts." },
    ];

    return (
      <>
        <Helmet>
          <meta name="description" content="Discover game-changing STR strategies, from smart home tech to AI-powered software. Your go-to resource for short-term rental innovation and success." />
        </Helmet>
        <div className="blog-landing-page">
          <h1 className="blog-landing-page-title">HostBuddy AI - Blog</h1>
          <h2 id='landing-page-description'>Discover game-changing STR strategies, from smart home tech to AI-powered software. Your go-to resource for short-term rental innovation and success.</h2>
          <div className="blog-tiles">
            {articles.map(article => (
              <a href={`/blog/${article.id}`} key={article.id} className="blog-tile">
                <div className="blog-tile-image-container">
                  <img src={article.img} alt={article.title} className="blog-tile-image" />
                </div>
                <h3 className="blog-tile-title">{article.title}</h3>
                <p className="blog-tile-date">{article.date}</p>
                <p className="blog-tile-description">{article.description}</p>
              </a>
            ))}
          </div>
        </div>
      </>
    );
};

export default BlogLandingPage;
